<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
          overflow: auto,
        }"
      >
        <a-form
          style="padding: 0 24px; margin-top: 3px; height: 80px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row
            class="flow-d-row"
            style="flex-wrap: wrap; height: 100%"
            :gutter="24"
          >
            <a-form-item
              style="margin-bottom: 0; margin-right: 15px"
              label="项目方案"
            >
              <a-select
                placeholder="请选择项目方案"
                style="width: 178px"
                v-model="form.planId"
              >
                <a-select-option v-for="item in planlist" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              style="margin-bottom: 0; margin-right: 15px"
              label="楼栋"
            >
              <a-select
                @change="onchangebuild"
                v-model="form.buildingId"
                placeholder="请选择楼栋"
                style="width: 178px"
              >
                <a-select-option v-for="item in buildinglist" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              style="margin-bottom: 0; margin-right: 15px"
              label="楼层"
            >
              <a-select
                @change="onchangefloor"
                v-model="form.floorId"
                placeholder="请选择楼层"
                style="width: 178px"
              >
                <a-select-option v-for="item in floorlist" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
            </a-col>
          </a-row>
        </a-form>
        <a-drawer
          title="落位信息"
          placement="right"
          width="400px"
          :visible="visible"
          :after-visible-change="afterVisibleChange"
          @close="onClose"
        >
          <div class="drow-title">
            <div>铺位信息</div>
            <div style="color: orange">铺位信息管理</div>
          </div>
          <div style="margin-bottom: 20px">
            <div style="margin-bottom: 10px" class="flow-d-row">
              <div style="flex: 1">
                楼层信息: {{ stores.buildingName }}/ F{{ stores.floorName }}
              </div>
              <div style="flex: 1">铺位ID: {{ stores.totalArea }}</div>
            </div>
            <div style="margin-bottom: 10px" class="flow-d-row">
              <div style="flex: 1">铺位编号:{{ stores.name }}</div>
              <div style="flex: 1">业态规划: {{ stores.totalArea }}</div>
            </div>
            <div style="margin-bottom: 10px" class="flow-d-row">
              <div style="flex: 1">建筑面积: {{ stores.totalArea }}</div>
              <div style="flex: 1">使用面积: {{ stores.useArea }}</div>
            </div>
            <div style="margin-bottom: 10px" class="flow-d-row">
              <div style="flex: 1">是否主力店: 是</div>
              <div style="flex: 1">租赁状态: 已签约</div>
            </div>
            <div style="margin-bottom: 10px" class="flow-d-row">
              <div style="flex: 1">租金标准: {{ stores.dayStandardFee }}</div>
              <div style="flex: 1">保底租金: {{ stores.dayLowestFee }}</div>
            </div>
          </div>
          <div class="drow-title">
            <div>铺位信息</div>
            <div style="color: orange">铺位信息管理</div>
          </div>
          <div style="margin-bottom: 20px">
            <a-form-item
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
              label="业态"
            >
              <a-cascader
                :field-names="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }"
                style="width: 200px"
                :options="BusinessTypes"
                placeholder="请选择业态"
                v-model="businessTypeIds"
                change-on-select
                @change="onChangebus"
              />
            </a-form-item>
            <!-- <div style="margin-bottom:10px" class="flow-d-row">
              <div style="flex:1">楼层信息: A座/</div>
              <div style="flex:1">铺位ID: 1565465154</div>
            </div>
            <div style="margin-bottom:10px" class="flow-d-row">
              <div style="flex:1">楼层信息: A座/</div>
              <div style="flex:1">铺位ID: 1565465154</div>
            </div>
            <div style="margin-bottom:10px" class="flow-d-row">
              <div style="flex:1">楼层信息: A座/</div>
              <div style="flex:1">铺位ID: 1565465154</div>
            </div>
            <div style="margin-bottom:10px" class="flow-d-row">
              <div style="flex:1">楼层信息: A座/</div>
              <div style="flex:1">铺位ID: 1565465154</div>
            </div> -->
          </div>
          <!-- <a-form-model
            :model="stores"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="铺位名">
              <a-input v-model="stores.name" />
            </a-form-model-item>
            <a-form-model-item label="建筑面积">
              <a-input v-model="stores.totalArea" />
            </a-form-model-item>
            <a-form-model-item label="使用面积">
              <a-input v-model="stores.useArea" />
            </a-form-model-item>
            <a-form-model-item label="计租面积">
              <a-input v-model="stores.rentArea" />
            </a-form-model-item>
            <a-form-model-item label="状态">
              <a-select
                style="width: 100%"
                show-search
                :filter-option="filterOption"
                v-model="stores.state"
              >
                <a-select-option value="0">启用</a-select-option>
                <a-select-option value="1">禁用</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="租赁状态">
              <a-select
                style="width: 100%"
                show-search
                :filter-option="filterOption"
                v-model="stores.rentState"
              >
                <a-select-option value="0">未租</a-select-option>
                <a-select-option value="1">已租</a-select-option>
                <a-select-option value="2">预租</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="标准价格">
              <a-input v-model="stores.dayLowestFee" />
            </a-form-model-item>
            <a-form-model-item label="最低价格">
              <a-input v-model="stores.dayStandardFee" />
            </a-form-model-item>
          </a-form-model> -->
        </a-drawer>
        <div class="">
          <div ref="dataStatisticsRef" style="height: 600px; width: 100%" />
        </div>
        <div
          class="flow-d-row posi"
          style="height: 40px; width: 100%; margin: -10px 0 15px 0"
        >
          <div style="margin-right: 15px" class="flow-d-row-center">
            <span class="color-div"></span>
            <span style="color: #999">空置的</span>
          </div>
          <div style="margin-right: 15px" class="flow-d-row-center">
            <span style="background-color: #755c1d" class="color-div"></span>
            <span style="color: #999">餐饮</span>
          </div>
          <div style="margin-right: 15px" class="flow-d-row-center">
            <span style="background-color: #1a5f75" class="color-div"></span>
            <span style="color: #999">零售</span>
          </div>
          <div style="margin-right: 15px" class="flow-d-row-center">
            <span style="background-color: #1a4c6f" class="color-div"></span>
            <span style="color: #999">跨界集合店</span>
          </div>
          <div style="margin-right: 15px" class="flow-d-row-center">
            <span style="background-color: #453c6d" class="color-div"></span>
            <span style="color: #999">生活服务</span>
          </div>
          <div style="margin-right: 15px" class="flow-d-row-center">
            <span style="background-color: #015d1b" class="color-div"></span>
            <span style="color: #999">文体娱乐</span>
          </div>
          <div style="margin-right: 15px" class="flow-d-row-center">
            <span style="background-color: #8c423f" class="color-div"></span>
            <span style="color: #999">其他类型</span>
          </div>
          <div style="margin-right: 15px" class="flow-d-row-center">
            <span style="background-color: #937b12" class="color-div"></span>
            <span style="color: #999">儿童亲子</span>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import http from '../../../http'
import * as echarts from 'echarts/core'
import {
  TooltipComponent,
  VisualMapComponent,
  GeoComponent,
} from 'echarts/components'
import { MapChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

echarts.use([
  TooltipComponent,
  VisualMapComponent,
  GeoComponent,
  MapChart,
  CanvasRenderer,
])
export default {
  data() {
    return {
      title: '',
      visible: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      value: 'name',
      key: '',
      selectedRowKey: [],
      stores: {
        name: '',
        value: '',
      },
      values: '',

      businessTypeIds: [],
      BusinessTypes: [],
      storelist: [],
      buildinglist: [],
      floorlist: [],
      planlist: [],
      form: {
        id: '',
        buildingId: undefined,
        planId: undefined,
        floorId: undefined,
        gzhSecret: '',
      },
    }
  },
  filters: {},
  created() {
    this.getplanlist()
    this.getpropertybuildlist()
    this.BusinessTypelist()
  },
  methods: {
    onchangebuild(e) {
      this.getpropertyfloorlist(e)
    },
    onchangefloor(e) {
      this.getpropertystorelist(e)
    },
    async getniu() {
      try {
        const res = await http.getniu()
        // console.log(res);
        echarts.registerMap('Beef_cuts_France', { svg: res.data })
        let that = this
        this.dataStatisticsRef = echarts.init(
          that.$refs.dataStatisticsRef,
          'macarons'
        )
        this.dataStatisticsRef.on('click', function (params) {
          if (params.data == undefined) {
            console.log(params)
          } else {
            // console.log(2);
            that.getbranddetail(that.form.planId, params.data.id)
          }
        })
        this.dataStatisticsRef.setOption({
          tooltip: {
            // formatter: (param) => {
            //   console.log(param);
            //   return [
            //     "项目名称" + ":" + "     " + param.data.text,
            //     " 项目地点" + ":" + "     " + param.data.name,
            //   ].join("<br>");
            // },
          },
          visualMap: {
            left: 'center',
            bottom: '-10%',
            min: 0,
            max: 7,
            orient: 'horizontal',
            text: ['', 'Price'],
            realtime: true,
            calculable: true,
            inRange: {
              color: [
                '#ABD17B',
                '#755C1D',
                '#1A5F75',
                '#1A4C6F',
                '#453C6D',
                '#015D1B',
                '#8C423F',
                '#937B12',
              ],
            },
          },
          series: [
            {
              name: 'French Beef Cuts',
              type: 'map',
              map: 'Beef_cuts_France',
              roam: true,
              label: {
                show: true,
                color: '#fff',
                // formatter: "{c}",
              },
              emphasis: {
                label: {
                  show: true, //高亮时文字不要出现
                  color: '#fff',
                },
              },
              selectedMode: false,
              data: this.storelist,
            },
          ],
        })
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    //获取当前项目方案列表
    async getplanlist() {
      try {
        const res = await http.getplanlist()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.planlist = data.plans
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    //获取当前项目楼栋列表
    async getpropertybuildlist() {
      try {
        const res = await http.getpropertybuildlist()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.buildinglist = data.buildings
          this.getpropertyfloorlist(data.buildings[0].id)
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    //根据楼栋Id获取楼层列表
    async getpropertyfloorlist(buildingId) {
      try {
        const res = await http.getpropertyfloorlist(buildingId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.floorlist = data.floors
          this.getpropertystorelist(data.floors[0].id)
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    //根据楼层Id铺位类型获取铺位列表
    async getpropertystorelist(floorId) {
      try {
        this.storelist = []
        const res = await http.getpropertystorelist(floorId)
        const { success, data } = res.data
        if (success) {
          data.stores.forEach(item => {
            this.values = ''
            let that = this
            if (item.businessTypeName != null) {
              if (item.businessTypeName.split(',')[0] == '餐饮') {
                that.values = 1
              }
              if (item.businessTypeName.split(',')[0] == '零售') {
                that.values = 2
              }
              if (item.businessTypeName.split(',')[0] == '跨界集合店') {
                that.values = 3
              }
              if (item.businessTypeName.split(',')[0] == '生活服务') {
                that.values = 4
              }
              if (item.businessTypeName.split(',')[0] == '文体娱乐') {
                that.values = 5
              }
              if (item.businessTypeName.split(',')[0] == '其他类型') {
                that.values = 6
              }
              if (item.businessTypeName.split(',')[0] == '儿童亲子') {
                that.values = 7
              }
              this.storelist.push({
                name: item.name,
                value: that.values,
                id: item.id,
              })
            } else {
              this.storelist.push({
                name: item.name,
                value: 0,
                id: item.id,
              })
            }
          })
          this.getniu()
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    //根据方案ID、铺位ID，获取铺位落位详情
    async getbranddetail(planId, storeId) {
      try {
        const res = await http.getbranddetail(planId, storeId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.stores = data.store
          this.visible = true
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    //设置铺位品牌落位
    async postplanbrand(form) {
      try {
        const res = await http.postplanbrand(form)
        const { success, data } = res.data
        if (success) {
          console.log(data)
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async BusinessTypelist() {
      try {
        const res = await http.BusinessTypelist()
        const { success, data } = res.data
        if (success) {
          this.BusinessTypes = data.businessTypes
          this.BusinessTypes.forEach(item => {
            item.children.forEach(item => {
              if (item.children == '') {
                item.children = undefined
              } else {
                item.children.forEach(item => {
                  if (item.children == '') {
                    item.children = undefined
                  } else {
                    item.children.forEach(item => {
                      if (item.children == '') {
                        item.children = undefined
                      }
                    })
                  }
                })
              }
            })
          })
          //   console.log(data.businessTypes);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    onClose() {
      this.visible = false
    },
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.posi {
  position: absolute;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.color-div {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #d9d9d9;
  margin-right: 7px;
}
.flow-d-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 43px;
}
.ant-form-item {
  height: 60px;
  margin-bottom: 15px;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
.flow-d-row {
  display: flex;
  flex-direction: row;
}
.drow-title {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
